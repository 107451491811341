import React, { useState, useContext } from 'react'
import { graphql, Link, navigate } from 'gatsby'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import { TransitionContext } from '../context/transition-context'

import MaskedImage from '../masked-image'

export const fragment = graphql`
  fragment AvailabilitiesPropertiesFragment on WordPress {
    portfolio(where: { orderby: { field: MENU_ORDER, order: ASC } }, first: 99) {
      edges {
        node {
          slug
          title
          portfolioFilters {
            edges {
              node {
                name
              }
            }
          }
          portfolioFields {
            hero {
              address
              image {
                sourceUrl
                mediaItemId
                modified
                altText
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const PropertiesList = ({ properties }) => {
  const [propertiesToList, setPropertiesToList] = useState(properties)
  const [filter, setFilter] = useState('all')
  const [render, setRender] = useState(0)
  const [fadeToggle, setFadeToggle] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [transitionActive, setTransitionActive] = useContext(TransitionContext)

  const forSaleProperties = properties.filter(
    property => property.node.portfolioFilters.edges[0].node.name === 'For sale'
  )
  const forLeaseProperties = properties.filter(
    property => property.node.portfolioFilters.edges[0].node.name === 'For lease'
  )

  const fade = useSpring({
    opacity: fadeToggle ? 0 : 1,
    from: { opacity: 0 },
    config: { duration: fadeToggle ? 300 : 0 }
  })

  const handleSaleFilter = () => {
    if (filter !== 'For Sale') {
      setFadeToggle(true)
      setFilter('For Sale')
      setTimeout(() => {
        setFadeToggle(false)
        setPropertiesToList(forSaleProperties)
        setRender(render + 1)
      }, 400)
    }
  }

  const handleLeaseFilter = () => {
    if (filter !== 'For Lease') {
      setFadeToggle(true)
      setFilter('For Lease')
      setTimeout(() => {
        setFadeToggle(false)
        setPropertiesToList(forLeaseProperties)
        setRender(render + 1)
      }, 400)
    }
  }

  return (
    <Outer>
      <Filters>
        <FilterTitle>Filter</FilterTitle>
        <button
          type="button"
          onClick={() => handleSaleFilter()}
          className={filter === 'For Sale' ? 'active' : ''}
        >
          For Sale
        </button>
        <button
          type="button"
          onClick={() => handleLeaseFilter()}
          className={filter === 'For Lease' ? 'active' : ''}
        >
          For Lease
        </button>
      </Filters>

      <MobileFilters
        onChange={e => (e.target.value === 'sale' ? handleSaleFilter() : handleLeaseFilter())}
      >
        <option value="sale" label="Properties for sale" />
        <option value="lease" label="Properties for lease" />
      </MobileFilters>

      <Properties style={fade}>
        {propertiesToList.map(property => (
          <Property
            key={property.node.title + render}
            to={`/availabilities/${property.node.slug}`}
            onClick={event => {
              event.preventDefault()
              setTransitionActive(true)
              setTimeout(() => {
                navigate(`/availabilities/${property.node.slug}`)
                setTransitionActive(false)
              }, 500)
            }}
          >
            <ImageWrapper>
              <ImageLink>
                <MaskedImage
                  fluid={property.node.portfolioFields.hero.image.imageFile.childImageSharp.fluid}
                  alt={property.node.portfolioFields.hero.image.altText}
                  offset="0"
                  isLink
                />
              </ImageLink>
            </ImageWrapper>
            <Title>
              {property.node.title}
              <br />
              {property.node.portfolioFields.hero.address}
            </Title>
          </Property>
        ))}
      </Properties>
    </Outer>
  )
}

export default PropertiesList

const Outer = styled.div`
  position: relative;
  width: 83.5%;
  /* margin: 10vw auto 5vw; */
  margin: 0 auto 5vw;

  @media (max-width: 650px) {
    width: 100%;
    padding: 0 30px;
  }
`

const Properties = styled(animated.section)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const ImageLink = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 2;
  }
`

const Property = styled(Link)`
  position: relative;
  width: 47.5%;
  margin-bottom: 5vw;
  text-decoration: none;

  @media (max-width: 650px) {
    width: 100%;
  }

  &:nth-child(even) {
    /* transform: translateY(60%); */
    transform: translateY(20%);

    @media (max-width: 650px) {
      transform: translateY(0);
    }
  }

  /*  
  If the last child is an even number, we need to account for the transform offset
  */
  &:nth-child(even):last-child {
    margin-bottom: calc(8% + 5rem);
  }
`

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  z-index: 5;

  &::before {
    content: '';
    display: block;
    padding-bottom: 74%;
  }
`

const Title = styled.p`
  color: var(--primary);
  font-family: ${props => props.theme.fonts.maison};
  font-weight: 400;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.4rem;
  line-height: 2;
  margin: 1rem 0 0;

  @media (max-width: 650px) {
    position: absolute;
    bottom: 12px;
    left: 20px;
    color: #fff;
    font-size: 1.8rem;
    line-height: 1.6;
    z-index: 5;
  }
`

const Filters = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  top: 0;
  right: 0;
  width: 47.5%;
  margin-bottom: 10rem;
  padding: 0 0 0 8vw;
  display: none;

  @media (max-width: 650px) {
    display: none;
  }

  button {
    color: rgba(0, 0, 0, 0.16);
    font-family: ${props => props.theme.fonts.butlet};
    font-weight: 700;
    font-size: 5vw;
    letter-spacing: -0.2rem;
    line-height: 1.2;
    background: none;
    border: none;
    padding: 0;
    transition: color 0.8s ease;
    cursor: pointer;

    &:hover,
    &.active {
      color: ${props => props.theme.colours.orange};
    }

    &:focus {
      outline: 0;
    }
  }
`

const FilterTitle = styled.span`
  display: block;
  color: ${props => props.theme.colours.darkGrey};
  color: var(--primary);
  font-family: ${props => props.theme.fonts.maison};
  font-weight: 400;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.4rem;
  line-height: 2;
  margin-bottom: 2rem;

  &:before {
    content: '';
    display: inline-block;
    background: ${props => props.theme.colours.orange};
    width: 0.9rem;
    height: 0.9rem;
    margin-right: 1.5rem;
  }
`

const MobileFilters = styled.select`
  display: none;
  font-family: ${props => props.theme.fonts.maison};
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.4rem;
  font-size: 1.8rem;
  line-height: 1.6;
  text-align-last: center;
  color: ${props => props.theme.colours.greyDark};
  line-height: 1.3;
  padding: 0.6em 1.4em 1em 0.8em;
  width: 80%;
  max-width: 40rem;
  box-sizing: border-box;
  margin: 0 auto 15vw;
  border: 0;
  border-bottom: 1px solid ${props => props.theme.colours.orange};
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgMTAgOSI+CiAgPHBhdGggaWQ9IlBvbHlnb25fMSIgZGF0YS1uYW1lPSJQb2x5Z29uIDEiIGQ9Ik01LDBsNSw5SDBaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMCA5KSByb3RhdGUoMTgwKSIgZmlsbD0iI2Q1NjUxYiIvPgo8L3N2Zz4K');
  background-repeat: no-repeat, repeat;
  background-position: right 1.6em top 35%, 0 0;
  background-size: 0.8em auto, 100%;
  display: none !important;

  @media (max-width: 650px) {
    display: block;
  }

  @media (max-width: 400px) {
    width: 100%;
    max-width: 100%;
  }

  &::-ms-expand {
    display: none;
  }

  &:focus {
    outline: none;
  }
`
