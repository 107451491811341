import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Hero from '../components/hero'
import Intro from '../components/availabilities/intro'
import PropertiesList from '../components/availabilities/properties-list'
import CallToAction from '../components/availabilities/call-to-action'
import Seo from '../components/seo'

const Availabilities = ({ data, pageContext }) => (
  <Layout>
    <Seo pageTitle={pageContext.title} {...data.wordPress.pageBy.seo} />
    <Hero {...data.wordPress.pageBy.availabilitiesFields.hero} />
    <Intro {...data.wordPress.pageBy.availabilitiesFields.intro} />
    <PropertiesList properties={data.wordPress.portfolio.edges} />
    <CallToAction {...data.wordPress.pageBy.availabilitiesFields.callToAction} />
  </Layout>
)

export default Availabilities

export const query = graphql`
  query($wordpressId: Int!) {
    wordPress {
      ...AvailabilitiesPropertiesFragment
      pageBy(pageId: $wordpressId) {
        ...SeoFragment
        availabilitiesFields {
          ...AvailabilitiesHeroFragment
          ...AvailabilitiesIntroFragment
          ...AvailabilitiesCallToActionFragment
        }
      }
    }
  }
`
