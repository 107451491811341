import React from 'react'
import { graphql } from 'gatsby'

import styled from 'styled-components'
import Button from '../button'

export const fragment = graphql`
  fragment AvailabilitiesCallToActionFragment on WordPress_Page_Availabilitiesfields {
    callToAction {
      buttonText
      buttonLink {
        ... on WordPress_Page {
          slug
        }
      }
    }
  }
`

const HomeIntro = ({ buttonText, buttonLink }) => (
  <Outer>{buttonLink && <Button text={buttonText} type="link" to={buttonLink.slug} />}</Outer>
)

const Outer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  padding: 0 16.5% 10rem;

  @media (max-width: 1260px) {
    padding: 0 12.5% 5rem;
  }

  @media (max-width: 650px) {
    padding: 0 30px 75px;
  }
`

export default HomeIntro
