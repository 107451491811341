import React from 'react'
import { graphql } from 'gatsby'

import styled from 'styled-components'
import BackgroundColor from '../background-color'
import FadeIn from '../fade-in'

export const fragment = graphql`
  fragment AvailabilitiesIntroFragment on WordPress_Page_Availabilitiesfields {
    intro {
      smallTitle
      title
      text
    }
  }
`

const HomeIntro = ({ smallTitle, title, text }) => (
  <Outer>
    <IntroBlock>
      <SmallTitle>
        <FadeIn>{smallTitle}</FadeIn>
      </SmallTitle>
      {/* <FadeIn>
        <h2>{title}</h2>
      </FadeIn> */}
      {/* <BlockText>
        <FadeIn>
          <Line />
          <TextWrapper>
            <p>{text}</p>
          </TextWrapper>
        </FadeIn>
      </BlockText> */}
    </IntroBlock>
    <BackgroundColor />
  </Outer>
)

const Outer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  /* padding: 17rem 16.5%; */
  padding: 5rem 16.5% 0;

  @media (max-width: 1260px) {
    padding: 5rem 12.5%;
  }

  @media (max-width: 800px) {
    padding: 65px 12.5% 0;
  }

  @media (max-width: 650px) {
    padding: 65px 30px 0;
  }
`

const IntroBlock = styled.div`
  width: 100%;
  margin-bottom: 2.5rem;

  @media (max-width: 767px) {
    width: 100%;
  }

  h2 {
    width: 55%;
    color: ${props => props.theme.colours.white};
    color: var(--primary);
    font-size: 4rem;
    font-family: ${props => props.theme.fonts.butler};
    line-height: 1.25;
    margin-bottom: 4.5rem;

    @media (max-width: 767px) {
      width: 100%;
      font-size: 28px;
      margin-bottom: 15px;
    }
  }

  p {
    display: flex;
    color: ${props => props.theme.colours.white};
    color: var(--primary);
    font-family: ${props => props.theme.fonts.maison};
    font-weight: 200;
    line-height: 1.2;
    width: 100%;
  }
`

const SmallTitle = styled.span`
  display: block;
  display: flex;
  color: ${props => props.theme.colours.white};
  color: var(--primary);
  font-family: ${props => props.theme.fonts.maison};
  font-weight: 400;
  font-size: 1.2rem;
  letter-spacing: 0.4rem;
  line-height: 2;
  text-transform: uppercase;
  margin-bottom: 4.5rem;

  @media (max-width: 1000px) {
    font-size: 1.8rem;
  }

  &:before {
    content: '';
    flex-shrink: 0;
    display: inline-block;
    background: ${props => props.theme.colours.orange};
    width: 0.8rem;
    height: 0.8rem;
    margin-right: 1.5rem;
    margin-top: 0.6rem;

    @media (max-width: 1000px) {
      margin-top: 1.1rem;
      width: 1rem;
      height: 1rem;
    }
  }
`

const TextWrapper = styled.div`
  width: 67%;
  margin-left: auto;

  @media (max-width: 767px) {
    width: 100%;
  }
`

const BlockText = styled.div`
  .fade-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  p {
    width: 100%;
    max-width: 70rem;
    color: ${props => props.theme.colours.white};
    color: var(--primary);
    font-family: ${props => props.theme.fonts.maison};
    font-weight: 200;
    font-size: 1.5rem;
    line-height: 2;
    margin-bottom: 5rem;
    white-space: pre-wrap;

    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
`

const Line = styled.div`
  background: ${props => props.theme.colours.lightGrey};
  height: 1px;
  width: 5.5rem;
  margin-right: 230px;

  @media (max-width: 767px) {
    margin: 30px 0 50px 0;
  }
`

export default HomeIntro
